<template>
  <div
    v-if="!hideBlocks"
    :class="block.backgroundColour ? `bg-${block.backgroundColour}` : null">
    <div
      data-custom-padding
      class="container px-4"
      :class="[topPadding, bottomPadding]">
      <div class="flex justify-between sm:items-center items-end mb-6 gap-x-4">
        <h2 class="md:typeset-2 typeset-3">{{ block.title }}</h2>
        <div class="flex items-end gap-6">
          <a
            :href="viewAllHref"
            class="text-link underline whitespace-no-wrap">
            {{ block.viewAllCta }}
          </a>
          <flickity-buttons
            display-classes="hidden sm:flex flex-no-shrink gap-6 mr-3 mt-3"
            :previous-disabled="previousDisabled"
            :next-disabled="nextDisabled"
            :flickity-id="flickityId" />
        </div>
      </div>
      <div class="saved-designs-carousel relative">
        <overlay
          v-if="!savedDesigns.length"
          :text="overlayText"
          absolutely-positioned />
        <flickity-carousel
          v-else
          :flickity-id="flickityId"
          class="lg:-mx-4"
          :cell-align="cellAlign"
          hide-arrows
          requires-flickity
          @update-selected-elements="updateElements($event)">
          <flickity-carousel-item
            v-for="(savedDesign, index) in savedDesigns"
            :key="savedDesign.id"
            :max-width="maxWidth"
            :data-index="index"
            :flickity-id="flickityId"
            class="sm:px-4"
            mobile-min-width="100%">
            <landing-page-saved-design
              :block="block"
              :saved-design="savedDesign"
              :show-ctas="showCtas(index)"
              :pull-up-ctas="!responsiveMode" />
          </flickity-carousel-item>
        </flickity-carousel>
        <flickity-buttons
          display-classes="flex sm:hidden justify-center flex-no-shrink gap-x-7 mt-6"
          :previous-disabled="previousDisabled"
          :next-disabled="nextDisabled"
          :flickity-id="flickityId" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/app/javascript/api";
import landingPageCarousel from "@/app/javascript/mixins/landingPageCarousel";

import Overlay from "@/app/javascript/components/shared/Overlay.vue";
import FlickityButtons from "@/app/javascript/components/shared/FlickityButtons.vue";
import FlickityCarousel from "@/app/javascript/components/shared/FlickityCarousel.vue";
import FlickityCarouselItem from "@/app/javascript/components/shared/FlickityCarouselItem.vue";
import LandingPageSavedDesign from "@/app/javascript/components/listings/LandingPageSavedDesign.vue";
import BaseBlock from "./BaseBlock.vue";

export default {
  name: "SavedDesignsBlock",
  components: {
    FlickityButtons,
    FlickityCarousel,
    FlickityCarouselItem,
    LandingPageSavedDesign,
    Overlay
  },
  extends: BaseBlock,
  mixins: [landingPageCarousel],
  props: {
    viewAllHref: {
      type: String,
      default: "#"
    },
    admin: {
      type: Boolean,
      default: false
    },
    responsiveMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      savedDesigns: [],
      screenWidth: window.innerWidth,
      elementsInView: [],
      hideBlocks: false
    };
  },
  computed: {
    largeScreen() {
      return this.screenWidth >= 992;
    },
    maxWidth() {
      if (this.largeScreen) {
        return "33.3%";
      }

      return "66%";
    },
    cellAlign() {
      if (this.largeScreen) {
        return "left";
      }

      return "center";
    },
    previousDisabled() {
      return this.isInView(0);
    },
    nextDisabled() {
      return this.isInView(this.savedDesigns.length - 1);
    },
    showCtas() {
      return index => {
        if (!this.responsiveMode) {
          return false;
        }

        return this.isInView(index);
      };
    },
    isInView() {
      return index => {
        return this.elementsInView.some(
          element => parseInt(element.dataset.index, 10) === index
        );
      };
    },
    overlayText() {
      if (!this.admin) return null;

      return "Not seeing any saved designs? You need to be logged in to your frontend account on the main site.";
    }
  },
  mounted() {
    this.getSavedDesigns();
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  methods: {
    async getSavedDesigns() {
      const response = await api.get(
        "/account/api/saved_designs?for_landing_page=true"
      );
      const imageCollection = response.data.saved_designs.map(
        savedDesign => savedDesign.image
      );
      await this.loadAllImages(imageCollection);
      const availableSavedDesigns = response.data.saved_designs.filter(
        savedDesign => !this.erroredImages.includes(savedDesign.image)
      );
      if (availableSavedDesigns.length <= 3) {
        this.savedDesigns = availableSavedDesigns;
      } else {
        // If there are more than 3 saved designs, we only want to show a multiple of three
        // This is to ensure it looks good and works well on tablet
        const numberOfDesignsToShow =
          Math.floor(availableSavedDesigns.length / 3) * 3;
        this.savedDesigns = availableSavedDesigns.slice(
          0,
          numberOfDesignsToShow
        );
      }

      if (this.savedDesigns.length === 0) {
        this.hideBlocks = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.saved-designs-carousel {
  min-height: 360px;
}
</style>
