import { Loader } from "@googlemaps/js-api-loader";
import physicalStoreLocations from "@/app/javascript/api/physicalStoreLocations";
// eslint-disable-next-line import/no-unresolved
import defaultMapMarker from "@/app/javascript/images/default-map-marker.svg?url";
// eslint-disable-next-line import/no-unresolved
import selectedMapMarker from "@/app/javascript/images/selected-map-marker.svg?url";

const loader = new Loader({
  apiKey: "AIzaSyD_nKH1eEz2LeuMNUtpicgwezUveBV-5-8",
  version: "weekly",
  libraries: ["places"]
});

const isUsStore = window.location.pathname.includes("/us");

const centerCoordinates = isUsStore
  ? {
      lat: 39.8097343,
      lng: -98.5556199
    }
  : {
      lat: 54.5180406,
      lng: -3.9196445
    };

function getMapZoom(isUs, width) {
  if (isUs) {
    return width <= 992 ? 3 : 4;
  }
  return width <= 992 ? 5 : 6;
}

const mapZoom = getMapZoom(isUsStore, window.innerWidth);

const mapOptions = {
  center: centerCoordinates,
  zoom: mapZoom,
  mapId: "5af85df0fd86f05e",
  disableDefaultUI: true,
  zoomControl: true
};

function calculateHaversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

async function calculateDistances(data, origin) {
  const estimatedDistances = [];
  const stores = [];

  data.forEach(store => {
    const storeLoc = store.getGeometry().get();
    const storeNum = store.getId();
    const estDistance = calculateHaversineDistance(
      origin.lat(),
      origin.lng(),
      storeLoc.lat(),
      storeLoc.lng()
    );
    estimatedDistances.push({
      storeNum,
      storeLoc,
      estDistance
    });
    stores.push(storeNum);
  });

  // Sort based on estimated distances
  estimatedDistances.sort((a, b) => a.estDistance - b.estDistance);

  // Take only the nearest 25
  const nearestStores = estimatedDistances.slice(0, 25);

  // Extract nearest destinations and corresponding storeNums
  const nearestDestinations = nearestStores.map(store => store.storeLoc);
  const nearestStoreNums = nearestStores.map(store => store.storeNum);

  // Retrieve the distances of each store from the user location
  // eslint-disable-next-line no-undef
  const service = new google.maps.DistanceMatrixService();
  // eslint-disable-next-line no-shadow
  const getDistanceMatrix = (service, parameters) =>
    new Promise((resolve, reject) => {
      service.getDistanceMatrix(parameters, (response, status) => {
        // eslint-disable-next-line no-undef
        if (status !== google.maps.DistanceMatrixStatus.OK) {
          reject(new Error(`Distance Matrix Status not OK, status: ${status}`));
          reject(response);
        } else {
          const distances = [];
          const results = response.rows[0].elements;
          for (let j = 0; j < results.length; j += 1) {
            const element = results[j];
            const distanceText = element.distance.text;
            const distanceVal = element.distance.value;
            const distanceObject = {
              storeid: nearestStoreNums[j],
              distanceText,
              distanceVal
            };
            distances.push(distanceObject);
          }

          resolve(distances);
        }
      });
    });

  const distancesList = await getDistanceMatrix(service, {
    origins: [origin],
    destinations: nearestDestinations,
    travelMode: "DRIVING",
    // eslint-disable-next-line no-undef
    unitSystem: google.maps.UnitSystem.IMPERIAL
  });

  distancesList.sort((first, second) => {
    return first.distanceVal - second.distanceVal;
  });

  return distancesList;
}

function createStoreCard(
  storeData,
  placeToAdd,
  map,
  storeForRanking = null,
  selectedStore = null
) {
  const listItem = document.createElement("li");
  listItem.classList.add(
    "bg-paper-1",
    "p-4",
    "flex",
    "justify-between",
    "mb-3",
    "cursor-pointer"
  );

  const storeDetailsDiv = document.createElement("div");
  storeDetailsDiv.classList.add("w-2/3");

  const nameElement = document.createElement("p");
  nameElement.classList.add("typeset-6", "mb-3");
  nameElement.textContent = storeData.getProperty("name");
  storeDetailsDiv.appendChild(nameElement);

  const addressElement = document.createElement("p");
  addressElement.classList.add("typeset-7");
  addressElement.innerHTML = storeData
    .getProperty("address")
    .replace(/,/g, "<br>");
  storeDetailsDiv.appendChild(addressElement);

  listItem.appendChild(storeDetailsDiv);

  const logoDistanceDiv = document.createElement("div");
  logoDistanceDiv.classList.add("typeset-6", "flex", "flex-col");

  if (storeData.getProperty("logo") !== "/logos/original/missing.png") {
    const logoImage = document.createElement("img");
    logoImage.classList.add("self-end", "mb-3");
    logoImage.src = storeData.getProperty("logo");
    if (window.innerWidth <= 992) {
      logoImage.width = 48;
      logoImage.height = 48;
    } else if (storeForRanking.distanceText) {
      logoImage.width = 64;
      logoImage.height = 64;
    } else {
      logoImage.width = 98;
      logoImage.height = 98;
    }
    logoDistanceDiv.appendChild(logoImage);
  }

  if (storeForRanking && storeForRanking.distanceText) {
    const distanceElement = document.createElement("p");
    distanceElement.classList.add("typeset-4", "mt-auto", "text-right");
    distanceElement.textContent = `${storeForRanking.distanceText}LES`;
    logoDistanceDiv.appendChild(distanceElement);
  }

  if (
    selectedStore &&
    storeForRanking.storeid === selectedStore.storeid &&
    window.innerWidth >= 992
  ) {
    listItem.classList.add("border", "border-ink");
  }

  listItem.addEventListener("click", () => {
    analytics.track("Store Locator List Item Clicked");
    const allListItems = document.querySelectorAll("li");
    allListItems.forEach(item => {
      if (item !== listItem) {
        item.classList.remove("border", "border-ink");
      }
    });
    listItem.classList.add("border", "border-ink");

    const storeLocation = storeData.getGeometry().get();
    map.panTo(storeLocation);

    storeData.setProperty("isSelected", true);
    map.data.forEach(feature => {
      if (feature !== storeData) {
        feature.setProperty("isSelected", false);
      }
    });
  });

  listItem.appendChild(logoDistanceDiv);

  placeToAdd.appendChild(listItem);
}

function showStoresList(map, stores, selectedStore) {
  const { data } = map;
  const results = document.querySelector("#store-results");
  results.replaceChildren();

  if (selectedStore) {
    const selectedIndex = stores.findIndex(
      store => store.storeid === selectedStore.storeid
    );
    if (selectedIndex !== -1) {
      stores.splice(selectedIndex, 1);
      stores.unshift(selectedStore);
    }
    if (window.innerWidth <= 992) {
      const selectedStoreDiv = document.querySelector("#selected-store");
      const selectedStoreFromStores = stores.find(
        store => store.storeid === selectedStore.storeid
      );
      const storeData = data.getFeatureById(selectedStoreFromStores.storeid);
      selectedStoreDiv.replaceChildren();
      createStoreCard(storeData, selectedStoreDiv, map);
    }
  }

  const containsDistanceVal = stores.some(store => "distanceVal" in store);
  if (containsDistanceVal) {
    const nearbyStoreElement = document.querySelector("#nearby-store-count");
    nearbyStoreElement.replaceChildren();

    const nearbyStoreCount = stores.filter(
      store => store.distanceVal < 1000000
    ).length;

    const nearbyStores = document.createElement("p");
    nearbyStores.textContent = `${nearbyStoreCount} stores nearby`;
    nearbyStores.classList.add("typeset-7", "text-ink-4", "mb-3");
    nearbyStoreElement.appendChild(nearbyStores);
  }

  stores.forEach(storeForRanking => {
    const storeData = data.getFeatureById(storeForRanking.storeid);
    createStoreCard(storeData, results, map, storeForRanking, selectedStore);
  });
}

let storeLocations = [];
async function fetchStoreLocations() {
  const response = await physicalStoreLocations.index();
  storeLocations = response.data.physical_store_locations;
}

// eslint-disable-next-line import/prefer-default-export
export async function initMap() {
  const mapContainer = document.querySelector("#map");
  if (!mapContainer) return;

  await fetchStoreLocations();

  loader
    .load()
    .then(google => {
      const map = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );

      map.data.addGeoJson(storeLocations);

      // Define the custom marker icons
      map.data.setStyle(feature => {
        const isSelected = feature.getProperty("isSelected");
        const iconUrl = isSelected ? selectedMapMarker : defaultMapMarker;
        const iconSize = isSelected ? 60 : 48;
        return {
          icon: {
            url: iconUrl,
            scaledSize: new google.maps.Size(iconSize, iconSize)
          }
        };
      });

      // Set the list of stores before ranking by distance
      const initialStores = [];
      map.data.forEach(store => {
        const storeNum = store.getId();
        initialStores.push({ storeid: storeNum });
      });

      showStoresList(map, initialStores, null);

      // Recenter the map to the selected store marker
      const selectedMarker = null;

      map.data.addListener("click", event => {
        analytics.track("Store Locator Map Marker Clicked");
        const selectedFeature = event.feature;
        selectedFeature.setProperty("isSelected", true);

        map.data.forEach(feature => {
          if (feature !== selectedFeature) {
            feature.setProperty("isSelected", false);
          }
        });

        const markerLocation = selectedFeature.getGeometry().get();

        if (selectedMarker) {
          selectedMarker.setMap(null);
        }

        map.panTo(markerLocation);

        showStoresList(map, initialStores, {
          storeid: selectedFeature.getId()
        });
      });

      // Switches tabs between list and map for mobile
      const mapElement = document.querySelector(".map-element");
      const listElement = document.querySelector(".list-element");

      const handleTabSelection = () => {
        const tabIndicator = document.querySelector(".tab-indicator");

        if (listElement.classList.contains("selected")) {
          document.querySelector("#map").classList.add("hidden", "md:block");
          document
            .querySelector("#store-results")
            .classList.remove("hidden", "md:block");
          tabIndicator.style.transform = "translateX(0%)";
        } else if (mapElement.classList.contains("selected")) {
          document.querySelector("#map").classList.remove("hidden", "md:block");
          document
            .querySelector("#store-results")
            .classList.add("hidden", "md:block");
          tabIndicator.style.transform = "translateX(100%)";
        }
      };

      handleTabSelection();

      const selectedStoreDiv = document.querySelector("#selected-store");

      mapElement.addEventListener("click", () => {
        listElement.classList.remove("selected");
        mapElement.classList.add("selected");
        selectedStoreDiv.classList.remove("hidden");
        handleTabSelection();
      });

      listElement.addEventListener("click", () => {
        mapElement.classList.remove("selected");
        listElement.classList.add("selected");
        selectedStoreDiv.classList.add("hidden");
        handleTabSelection();
      });

      const input = document.querySelector("#map-searchbar");
      const options = {
        types: ["address"]
      };
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      autocomplete.setFields(["address_components", "geometry", "name"]);

      // Set the origin point when the user selects an address
      const originMarker = new google.maps.Marker({ map: null });
      let originLocation = map.getCenter();

      autocomplete.addListener("place_changed", async () => {
        analytics.track("Store Locator Place Changed");
        originMarker.setVisible(false);
        originLocation = map.getCenter();
        const place = autocomplete.getPlace();

        originLocation = place.geometry.location;
        map.setCenter(originLocation);
        map.setZoom(14);

        originMarker.setPosition(originLocation);
        originMarker.setVisible(true);

        let rankedStores = [];

        try {
          // Use the selected address to calculate distances to stores
          rankedStores = await calculateDistances(map.data, originLocation);
        } catch (err) {
          console.log(err);
          throw new Error(err);
        }

        showStoresList(map, rankedStores, null);
      });
    })
    .catch(e => {
      console.log(e);
      throw e;
    });
}

initMap();
