<template>
  <div :class="displayClasses">
    <div
      class="carousel-arrow show-background arrow-left w-6 h-6 inline-flex items-center justify-center cursor-pointer z-1 no-shadow"
      :class="arrowClasses(previousDisabled)"
      @click="previous">
      <svg-icon name="leftarrow-small" />
    </div>
    <div
      class="carousel-arrow show-background arrow-right w-6 h-6 inline-flex items-center justify-center cursor-pointer z-1 no-shadow"
      :class="arrowClasses(nextDisabled)"
      @click="next">
      <svg-icon name="rightarrow-small" />
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/app/javascript/components/shared/SvgIcon.vue";

export default {
  name: "FlickityButtons",
  components: {
    SvgIcon
  },
  props: {
    flickityId: {
      type: String,
      default: null
    },
    displayClasses: {
      type: String,
      default: "flex flex-no-shrink gap-6"
    },
    previousDisabled: {
      type: Boolean,
      default: false
    },
    nextDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    previous() {
      window.dispatchEvent(
        new CustomEvent("flickityNavigate", {
          detail: { id: this.flickityId, next: false }
        })
      );
    },
    next() {
      window.dispatchEvent(
        new CustomEvent("flickityNavigate", {
          detail: { id: this.flickityId, next: true }
        })
      );
    },
    arrowClasses(disabledArrow) {
      return disabledArrow ? "opacity-50 prevent-scaling" : "";
    }
  }
};
</script>
