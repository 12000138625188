<script>
import blockConfig from "../../../../javascript/utils/landingPageBlocks";

export default {
  name: "BaseBlock",
  props: {
    block: {
      type: Object,
      required: true
    },
    previousBlock: {
      type: Object,
      default: null
    },
    nextBlock: {
      type: Object,
      default: null
    }
  },
  computed: {
    mixedCase() {
      return text => {
        return text.replace(/(\b[A-Z][A-Z]+)/g, "<em>$1</em>");
      };
    },
    bgMatchesPrev() {
      if (!this.previousBlock) return false;
      if (blockConfig[this.previousBlock.type].prevent_smart_padding)
        return false;
      if (!this.previousBlock.backgroundColour && !this.block.backgroundColour)
        return true;

      return (
        this.block.backgroundColour === this.previousBlock.backgroundColour
      );
    },
    bgMatchesNext() {
      if (!this.nextBlock) return false;
      if (blockConfig[this.nextBlock.type].prevent_smart_padding) return false;
      if (!this.nextBlock.backgroundColour && !this.block.backgroundColour)
        return true;

      return this.block.backgroundColour === this.nextBlock.backgroundColour;
    },
    topPadding() {
      if (this.block.overrideTopPadding) {
        return this.explicitPaddingString("top", this.block.explicitTopPadding);
      }

      return this.defaultTopPadding;
    },
    bottomPadding() {
      if (this.block.overrideBottomPadding) {
        return this.explicitPaddingString(
          "bottom",
          this.block.explicitBottomPadding
        );
      }

      return this.defaultBottomPadding;
    },
    explicitPaddingString() {
      return (position, values) => {
        if (position === "top") {
          return `pt-${values.xs} sm:pt-${values.sm} md:pt-${values.md} lg:pt-${values.lg}`;
        }
        return `pb-${values.xs} sm:pb-${values.sm} md:pb-${values.md} lg:pb-${values.lg}`;
      };
    },
    dynamicH1ForField() {
      return field => {
        if (!this.block.h1 && field === blockConfig[this.block.type].defaultH1)
          return "h1";
        return this.block.h1 === field ? `h1` : `p`;
      };
    }
  }
};
</script>
