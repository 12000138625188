<template>
  <div
    :class="[
      topPadding,
      bottomPadding,
      block.backgroundColour
        ? `bg-${block.backgroundColour} transparent-images`
        : null
    ]">
    <h3 class="block sm:hidden typeset-3 mb-6 container">{{ block.title }}</h3>
    <div class="container flex flex-col-reverse sm:block">
      <div class="flex justify-between mb-4 md:mb-6 mx-auto sm:mx-0">
        <div
          v-if="block.title"
          class="typeset-2 hidden sm:block">
          {{ block.title }}
        </div>
        <flickity-buttons
          v-if="shouldShowArrows"
          display-classes="flex flex-no-shrink gap-7 mt-5 sm:mt-4 mr-4"
          :previous-disabled="previousDisabled"
          :next-disabled="nextDisabled"
          :flickity-id="flickityId" />
      </div>
      <div
        v-if="currentProducts.length"
        class="landing-products-carousel js-impression-list md:mb-0 mb-3 transparent-images"
        data-situation="landing_page"
        data-block-type="product_carousel">
        <flickity-carousel
          :flickity-id="flickityId"
          class="h-full gap-8"
          hide-arrows
          requires-flickity
          @update-selected-elements="updateElements($event)">
          <flickity-carousel-item
            v-for="(listing, index) in currentProducts"
            :key="listing.analytics_id"
            :last-in-list="index === listing.length - 1"
            max-width="16.66%"
            lg-min-width="16.66%"
            sm-min-width="33.33%"
            md-min-width="25%"
            :data-index="index"
            :style="{ height: cellHeight + 'px' }"
            :flickity-id="flickityId"
            mobile-min-width="50%"
            class="px-3">
            <listing
              :listing="listing"
              :data-id="listing.analytics_id"
              :allow-favourites-controls="!admin"
              :disable-favourites="admin"
              text-align="center"
              show-pricing />
          </flickity-carousel-item>
        </flickity-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash-es";
import api from "@/app/javascript/api/admin/landingPages";
import landingPageCarousel from "@/app/javascript/mixins/landingPageCarousel";

import FlickityButtons from "@/app/javascript/components/shared/FlickityButtons.vue";
import FlickityCarousel from "@/app/javascript/components/shared/FlickityCarousel.vue";
import FlickityCarouselItem from "@/app/javascript/components/shared/FlickityCarouselItem.vue";
import Listing from "@/app/javascript/components/shared/Listing.vue";
import BaseBlock from "./BaseBlock.vue";

export default {
  name: "ProductCarousel",
  components: {
    Listing,
    FlickityButtons,
    FlickityCarousel,
    FlickityCarouselItem
  },
  extends: BaseBlock,
  mixins: [landingPageCarousel],
  props: {
    storeCode: {
      type: String,
      default: ""
    },
    // In the FA Design tool, we won't pass this as a prop
    // But on the FE, where the products won't change, we pass these in as a prop
    // to avoid a slower API call
    decoratedProducts: {
      type: Array,
      default: () => []
    },
    admin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentProducts: [],
      key: 0,
      elementsInView: []
    };
  },
  computed: {
    shouldShowArrows() {
      return this.isDesktop ? this.currentProducts.length > 6 : true;
    },
    isDesktop() {
      return window.innerWidth >= 1280;
    }
  },
  watch: {
    block: {
      handler(val, oldVal) {
        if (!isEqual(val.products, oldVal.products)) {
          this.key += 1;
          this.loadCarousel();
        }
        this.$nextTick(() => {
          this.flkty.resize();
        });
      },
      deep: true
    }
  },
  mounted() {
    this.loadCarousel();
  },
  methods: {
    async loadCarousel() {
      this.currentProducts = [];
      const products = this.decoratedProducts.length
        ? this.decoratedProducts
        : await this.fetchProducts();
      const imageCollection = products.map(product => product.imageUrl);
      await this.loadAllImages(imageCollection);
      this.currentProducts = products.filter(
        product => !this.erroredImages.includes(product.imageUrl)
      );
      this.$nextTick(() => {
        this.setupPageForNewProducts();
      });
    },
    async fetchProducts() {
      const { data } = await api.fetchProducts(this.block.products);
      return data;
    }
  }
};
</script>
