<template>
  <div
    class="landing-page-saved-design"
    :class="{ 'pull-up-ctas': pullUpCtas }">
    <div class="pb-5 aspect-ratio-1x1 flex items-center justify-center">
      <img
        :src="savedDesign.image"
        class="object-contain max-w-full max-h-full" />
    </div>

    <div
      class="landing-page-saved-design-ctas z-5"
      :class="{ 'show-ctas': showCtas }">
      <a
        class="flex bg-paper gap-3 mb-4"
        :href="savedDesign.originalProductLink">
        <div class="flex items-center justify-center w-8 h-8">
          <img
            class="img-responsive"
            :src="savedDesign.originalProductImage" />
        </div>
        <div class="flex-no-shrink text-left py-3">
          <p class="typeset-6">{{ savedDesign.product_name }}</p>
          <p class="typeset-7">{{ savedDesign.product_type_name }}</p>
        </div>
        <div class="ml-auto py-3 mr-3">
          <p class="typeset-6">{{ savedDesign.price.actual }}</p>
          <p
            v-if="savedDesign.price.has_discount"
            class="typeset-8 strikethrough bg-sunflower">
            {{ savedDesign.price.base }}
          </p>
        </div>
      </a>
      <div class="flex flex-col lg:flex-row gap-4">
        <a
          :href="savedDesign.edit_path"
          class="btn btn-primary btn-block btn-large">
          {{ block.continueEditingCta }}
        </a>
        <a
          :href="savedDesign.add_to_cart_path"
          class="btn btn-secondary btn-block btn-large">
          {{ block.addToCartCta }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPageSavedDesign",
  props: {
    savedDesign: {
      type: Object,
      default: () => {}
    },
    block: {
      type: Object,
      default: () => {}
    },
    showCtas: {
      type: Boolean,
      default: false
    },
    pullUpCtas: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.landing-page-saved-design {
  @media screen and (min-width: $screen-md-min) {
    scale: 0.97;
  }

  transition: scale 0.15s ease;

  .landing-page-saved-design-ctas {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s ease 0.15s;

    &.show-ctas {
      visibility: visible;
      opacity: 1;
    }
  }

  &.pull-up-ctas {
    margin-bottom: -4rem;

    .landing-page-saved-design-ctas {
      transform: translateY(-4rem);
    }
  }

  &:hover {
    scale: 1;

    .landing-page-saved-design-ctas {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
