export default function listingTemplateForTooltip(listing) {
  return `
    <a href="${listing.link}" class="block" style="min-width: 256px;">
      <div class="flex items-center bg-paper-2 -ml-2">
        <div class="flex-no-shrink w-8 mr-3">
          <div class="aspect-ratio-1x1">
            <img
              alt="${listing.name} - ${listing.product_type_name}"
              src="${listing.imageUrl}"
              class="block h-full w-full object-contain img-responsive">
          </div>
        </div>
        <div class="ellipsis text-left">
          <h4 class="product-name typeset-6 ellipsis mb-1">${listing.name}</h4>
          <h5 class="product-type-name typeset-7 ellipsis">${listing.product_type_name}</h5>
        </div>
      </div>
    </a>
  `;
}
