import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      flickityId: uuidv4(),
      elementsInView: [],
      erroredImages: []
    };
  },
  computed: {
    defaultTopPadding() {
      return this.bgMatchesPrev ? "pt-5" : "pt-7";
    },
    defaultBottomPadding() {
      return this.bgMatchesNext ? "pb-5" : "pb-7";
    },
    previousDisabled() {
      return this.isInView(0) || !this.elementsInView.length;
    },
    nextDisabled() {
      return (
        this.isInView(this.currentProducts.length - 1) ||
        !this.elementsInView.length
      );
    },
    isInView() {
      return index => {
        return this.elementsInView.some(
          element => parseInt(element.dataset.index, 10) === index
        );
      };
    }
  },
  methods: {
    updateElements(selected) {
      this.elementsInView = selected;
    },
    loadAllImages(imageCollection) {
      return Promise.all(
        imageCollection.map(async url => {
          return new Promise(resolve => {
            const image = new Image();
            image.addEventListener("load", () => {
              resolve();
            });
            image.addEventListener("error", () => {
              this.erroredImages.push(url);
              resolve();
            });
            image.src = url;
          });
        })
      );
    },
    setupPageForNewProducts() {
      this.setupFavourites();
      this.notifyHtmlChanged();
    },
    setupFavourites() {
      window.resetFavourites();
    },
    notifyHtmlChanged() {
      const htmlChanged = new Event("htmlChanged");
      window.dispatchEvent(htmlChanged);
    }
  }
};
